import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Caption = makeShortcode("Caption");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "deleting-is-a-destructive-action-to-protect-user-resources-your-product-should-support-undo-where-possible-if-undo-support-is-impossible-request-user-confirmation-before-deleting-a-resource-or-asset"
    }}>{`Deleting is a destructive action. To protect user resources, your product should support ‘undo’ where possible. If ‘undo’ support is impossible, request user confirmation before deleting a resource or asset.`}</h3>
    <h4 {...{
      "id": "status"
    }}>{`Status:`}</h4>
    <p>{`Experimental`}</p>
    <h4 {...{
      "id": "maintainers"
    }}>{`Maintainers:`}</h4>
    <p><a parentName="p" {...{
        "href": "https://github.com/vikkipaterson"
      }}>{`Vikki Paterson`}</a></p>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">High impact</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Medium impact</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Low impact</AnchorLink>
    </AnchorLinks>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "47.69021739130435%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAAsSAAALEgHS3X78AAABu0lEQVQoz62R3UrjUBSF+zjWFzA18aoP0hbvBhSrLyCCJU3S5pykSX8wTYeB0RasVvvj1TgPkLzBWLwSLxS8EovY5dmnP6Di3QQW+2Rnne+sfZLAf3ym0ykSt+N/uOj3MBhdoT8c4nIwxFCs/1z//VbkJV9faDAaoXvew814LKEJzh2sJJNIp9NQlBTWFEVqXVWhqhpUTYOmbSxFPSVFvpSs1FtJruL38ckMWLY5srlNuBUfplWGzThs2wZjDIZhwDRNWJa1FPUdx5FiwkfeQqGAKIpnQFNsymazaLV+CmgFnu/DF6pWq+h0Ouh2uzg/O8OFUE+sg6MjVISPPItaKpUQx9EMSClyuRxqtRqKRV2moB4leHh8xGQywfPrK56mb3gRG9qnpygcHkoI+RY1jucJdV2XCYMgkONwzpei9Ptic1s3cG8x3JkMbXElJYfDmXtc15UhPgAzmQzCMJQfaQzP82TC3XweP7a2cLCTx6+dPYTbeVSLBlzfQ2XupZHpnqMo+pqQgDQ6iYyNRgNhs4mg1YIXNlEWfSYO4mISNhcdTIwPwEVCgtTr9SWQktLPofe6qATin67lM/AdyIhBwggq5CQAAAAASUVORK5CYII=')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "Example of a deletion pattern using a modal in context",
        "title": "Example of a deletion pattern using a modal in context",
        "src": "/static/9b63ff7d1f20d723fe869ba80f21769a/fb070/1.png",
        "srcSet": ["/static/9b63ff7d1f20d723fe869ba80f21769a/d6747/1.png 288w", "/static/9b63ff7d1f20d723fe869ba80f21769a/09548/1.png 576w", "/static/9b63ff7d1f20d723fe869ba80f21769a/fb070/1.png 1152w", "/static/9b63ff7d1f20d723fe869ba80f21769a/c3e47/1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "loading": "lazy"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">Example of a deletion pattern using a moda</Caption>
    <h2 {...{
      "id": "high-impact"
    }}>{`High impact`}</h2>
    <p>{`A high-impact deletion cannot be reversed. The action would result in a significant loss for a user if done accidentally.`}</p>
    <p>{`For high-impact scenarios, a user should confirm the action by manually entering the name of the resource. The ‘Delete’ button is enabled when the text entered perfectly matches the resource name.`}</p>
    <p>{`The text should tell the user the consequences of the deletion and that the action cannot be undone. Optionally, when the delete button is selected it changes to `}<inlineCode parentName="p">{`Deleting…`}</inlineCode>{`.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.733695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABOElEQVQoz42T3aqCQBSFu+8mewSfwJcSxduuez7fQPIHJMwyf1JLBaFa56yBEcsKBz72jM6svfZsXeB/PJ9PyPjO3CH3LsZi3zY+Ho8hyvmnxIyL8YPL5YI4jnE+n5EkCfI8x+12Q9u2aJpGRMJn5Hq9oqqq74LH4xGO48D3fbiuK4SZpCgKAROQuq4HQcavgnRHoSAIBBTe7XZiTsd8T+hKCtL5RFAuuKEsywEe5AHSdd2kZPJTkKWkaYosy0RkebJkivd9P4GJ2KhB8FeHx0M6fYeOPzpkpu12C8uyYJomDMMQUdd1bDYbcZe8xyiKcDgcBJyfTqdXh3LBcjVNw3q9xmq1EiiKguVyCVVVYds29vs9PM8TzSKch2GI+/0+dUhhdpOfDTstkV1maZ/ukLyUPPcO5/56f000g9dFzJdzAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of an un-populated high-impact deletion modal",
            "title": "Example of an un-populated high-impact deletion modal",
            "src": "/static/f4b896f8024e27a428d7100d0bc09640/fb070/2.png",
            "srcSet": ["/static/f4b896f8024e27a428d7100d0bc09640/d6747/2.png 288w", "/static/f4b896f8024e27a428d7100d0bc09640/09548/2.png 576w", "/static/f4b896f8024e27a428d7100d0bc09640/fb070/2.png 1152w", "/static/f4b896f8024e27a428d7100d0bc09640/c3e47/2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.733695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABZElEQVQoz5WTzU7CQBRGWbpou1EXapSFictufSgTGjRGXOhCFj6ZL0AoTKW6IVQgtPxNG4yln3MvDlQMBCc5ue309szXTluAGlmWQdd1dh26t5CXbWrcBd1byE9EUYQgCNDv99HtdjEYDDCdThHH8S+klAxdG41Gm4Ukc10XrVYLnueh1+vxImEYMrQAMR6PWTaZTLhuFHY6HRaR0Pd9rkIIPqbEtCD1UCotpKR/hPqEGobDId9AlaAb1x9bzxFbhSSiFATJqDlJEmam+MxVTaJ65vP5Srhth/MjUUhFnEP+zGcLCddlQkpRrVZRLpfhOA5KpRJzpagoxN0DPiqPaF9XFtzco+3cInh6RirjVUIdl3bOtm1YlgXTNGEYBizFnqJomHg5Psf7yQWa+6fwDs7gHRYhrCO82ZdIw2ghVK5lwjRNeUdrtRp/Opq6QihkvYEZ0RAr3CZmrz6yr/T/73DXX+8bCUJyLzbDanoAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a populated high-impact deletion modal",
            "title": "Example of a populated high-impact deletion modal",
            "src": "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/fb070/3.png",
            "srcSet": ["/static/20f289e1f4a480c64c8fcfc1f08b7b4c/d6747/3.png 288w", "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/09548/3.png 576w", "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/fb070/3.png 1152w", "/static/20f289e1f4a480c64c8fcfc1f08b7b4c/c3e47/3.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.733695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABMElEQVQoz5WSS26DQAyGs2cTOENzAO6EFNizzS0rGkRUVapAJCEREF7l5ea35BFNG5KO9MnDYP8e27Og6xrHkcTe8uwS38VU7J7jMAzKyv6vxLCL6cH5fKYwDGm/31Mcx5QkCV0uFyrLkoqiYAtwJuD8rmAUReR5HgVBQL7vszCSnE4nBglAlmUsluf5vCBuByEI7nY7ttvtlve4MRLCJ03TeUH5gBOyT0GQlH1b8qwgmo2f4ozAuq6paRqmbVtF13UK+CBWCc5NeLqaa2BVVcz0tuCHoIjBcbPZkOM4tF6vGdu2ybIscl2X3j8+eTDH45FLFX6VLOrol2maZBgG6bpOy+WSraZptFq90OtbwBM/HA6Pn430ENPEs8GkBZly236poL7vFfLQ/93DR37y7xu/LIfv4HKmZgAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a high-impact deletion modal in its \"deleting\" state",
            "title": "Example of a high-impact deletion modal in its \"deleting\" state",
            "src": "/static/93638eca3143888b9c8c9ef99ca9b87a/fb070/5_0.png",
            "srcSet": ["/static/93638eca3143888b9c8c9ef99ca9b87a/d6747/5_0.png 288w", "/static/93638eca3143888b9c8c9ef99ca9b87a/09548/5_0.png 576w", "/static/93638eca3143888b9c8c9ef99ca9b87a/fb070/5_0.png 1152w", "/static/93638eca3143888b9c8c9ef99ca9b87a/c3e47/5_0.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.733695652173914%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsSAAALEgHS3X78AAABKUlEQVQoz5VSSW6DQBDkzgXzBz+APyGB71z9TQKBQ6LIFpYwZscG40pqpEbYiUkyUqlnqa6eXjR8rdvtBrGP+OsSrjYXe0Ycx3Gysv8pMK02v8iyDLvdDofDAUmSIE1TVFWFpmlQ17WyBO8EvH8quN/v4fs+oihCGIZKmEGOx6MCAxBFUSixsiyXBfk7ClEwjmNlgyBQe/6YAcnJ83xZUA4kMfocdJK0H1NeFGSx+ShkOnZdh/P5rND3/YRhGCaQQ99JcKnD80XHtm3vfiq4ExQxkrfbLTabDRzHUXBdF7Ztw/M8vL1/4HQ6qfoxVcG3lEWd9bIsC6ZpYrVawTAMZXVdx3q9xkvwqsSkw4tjIzVkNzk27LRAutxfLop3vV7vIIP+7xr+xpO3T+f/iBtWR0zlAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a high-impact deletion modal in its \"deleting\" state",
            "title": "Example of a high-impact deletion modal in its \"deleting\" state",
            "src": "/static/da201b400a72ac2e3c76ee5c4ef6f648/fb070/5_1.png",
            "srcSet": ["/static/da201b400a72ac2e3c76ee5c4ef6f648/d6747/5_1.png 288w", "/static/da201b400a72ac2e3c76ee5c4ef6f648/09548/5_1.png 576w", "/static/da201b400a72ac2e3c76ee5c4ef6f648/fb070/5_1.png 1152w", "/static/da201b400a72ac2e3c76ee5c4ef6f648/c3e47/5_1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <p>{`You can choose to show a notification confirming deletion is completed. This is useful when the deletion of the resource takes more than a few moments.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.5%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAYAAAD5nd/tAAAACXBIWXMAAAsSAAALEgHS3X78AAAA1UlEQVQoz62QWwuCQBSE+/8/KXqoICKp7Oqt1i7a1VKiNNI8Uy4pa0UUNPBxHs6c2WELAM4pRJTNT4DyfpEC/qwkkFLi+5PJZCebGt6QNHdC3Y1G/a1BA2fEGQc2LUKH+1KJGbmGMd8B6nGCqiujwiSU9BqKahVlU0J92UHfZzDDJfclWfS4ERtmSpcssKFcpmjvNbT2Kpo7BbKnQw8taJGFabTmfQhfBs6CFfSLBeVgYuCxO2P0dgYMfw7zuoIdOb815EbQ20/PdvRFoBgqHoi8+J50A4YDbzro/EpRAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a successful delete notification",
            "title": "Example of a successful delete notification",
            "src": "/static/8121e140e2540269b43a9c4f75be7071/fb070/5_2.png",
            "srcSet": ["/static/8121e140e2540269b43a9c4f75be7071/d6747/5_2.png 288w", "/static/8121e140e2540269b43a9c4f75be7071/09548/5_2.png 576w", "/static/8121e140e2540269b43a9c4f75be7071/fb070/5_2.png 1152w", "/static/8121e140e2540269b43a9c4f75be7071/c3e47/5_2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "medium-impact"
    }}>{`Medium impact`}</h2>
    <p>{`A medium-impact deletion is one that cannot be reversed, but would not be catastrophic if done accidentally. When deleting is medium-impact, a confirmation dialog should be presented to the user which displays:`}</p>
    <ul>
      <li parentName="ul">{`The name of the resource`}</li>
      <li parentName="ul">{`Consequences of the deletion`}</li>
      <li parentName="ul">{`The action cannot be undone`}</li>
    </ul>
    <p>{`Optionally, when the delete button is selected it changes to `}<inlineCode parentName="p">{`Deleting…`}</inlineCode>{`.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.66847826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABZUlEQVQoz6WS3UoCQRiG96TQQJA6S4LOuwUvJyQ0UojqCryOILqVotPWHXdBUMLcTWN33T/d/7eZsTGjrQP74OEbPvZ7eJkdKc9zMFiJ8zaIfQmfJYbbltiXNu1hGCIIAiwWCyyXS/i+vz4XIb5PkuQr4Wbk2WwGVVUxHA6haRoMw4DneXyJdYHrunAch3fTNLm4UKjrOhRF4bJ+v4/BYMDlrL+Ox5yX0Qjv0yl8KnOp1KPEUVQsZEls28Z8PudYlsVhMy8K4dBFh6Zx4wh+EnO8OEacZd+FrDI6ZElkWQYhhCdlndDeo7z1FDgUWyGwewyFd+tZRmhaP4Xskuv1OiqVCsrlMkqlEmePIu3u4u6ghsnxCUj1ECqnBm3/CGSnCvv2fiWkP2ctjGn0breLRqOBVquFZrPJOaecUh4vLmF1bjBpX8EQdK6hn7URPDythGm2eof/fYO/vkN2j2maFpL/xYbjAxC1ImXWoXgIAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a standard delete modal",
            "title": "Example of a standard delete modal",
            "src": "/static/8eabc55d88798fec2ccc231962512f7a/fb070/6.png",
            "srcSet": ["/static/8eabc55d88798fec2ccc231962512f7a/d6747/6.png 288w", "/static/8eabc55d88798fec2ccc231962512f7a/09548/6.png 576w", "/static/8eabc55d88798fec2ccc231962512f7a/fb070/6.png 1152w", "/static/8eabc55d88798fec2ccc231962512f7a/c3e47/6.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.66847826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABKUlEQVQoz62T3WqDQBCF91ISfKM8TcAbfQ5fp9CLPkg1RkEItQQDSjT+xt+cdoZuaohtIXTgMMu6c/ZjdhSXywUkCrl+RLJe4Cvk5qMh68XUvWkaVFWFuq5xPp9RluV1PSd5vu/7b8IpchRFcF0Xu90OnufhcDigKAouoiyV5zmyLON8PB7ZeNYwDENsNhs222638H2fzSnv93tWEASI45jp5QVd180bEkmapjidTqwkSVi0l+fFdZ/oprR3hhTjODKJZVlwHIdJKZNs22aquq5uyH41pCavViuoqorFYgFFUVjL5RJCCDw9vyDNSrwHb0xJRrKXbdveG9ItpmlC0zQYhgFd11m0Xq/XeP0kp7NEOH1pmoKbV/6PGfxxDqmPwzDM6q+/SX77AAeMOITjOm8eAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a standard delete modal in its \"deleting\" state",
            "title": "Example of a standard delete modal in its \"deleting\" state",
            "src": "/static/ba77e6755749bc30e73c1b839b3fdada/fb070/1_1.png",
            "srcSet": ["/static/ba77e6755749bc30e73c1b839b3fdada/d6747/1_1.png 288w", "/static/ba77e6755749bc30e73c1b839b3fdada/09548/1_1.png 576w", "/static/ba77e6755749bc30e73c1b839b3fdada/fb070/1_1.png 1152w", "/static/ba77e6755749bc30e73c1b839b3fdada/c3e47/1_1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "53.66847826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAYAAAB/Ca1DAAAACXBIWXMAAAsSAAALEgHS3X78AAABJUlEQVQoz61T22qDQBDdx5jiH/k3gg+a38gPtfRHjDcQAoIoKPGaxEsSTztDt7XEthA6cJhldufs2bO7YpomECjk+BHIfoGPkMVHQ/aLOXvf9zidTjifz+i6Dsfj8XO8BLn+crl8KZxLzrIMvu9jv98jCAKkaYq2bbmJskTTNKjrmvPhcGDiRcIkSeA4DpN5nocwDJmcchzHjCiKkOc5q5cbjOO4TEhKyrJEVVWMoigYVKNGWSd1c7V3hBS3242V2LYN13VZKWXCbrdjVeTn/Oi/EpLJmqZBVVUoioLVasVYr58ghMDzyyuqukX+7rMkkl4Ow3BPSLtst1sYhgHLsmCaJmOz2UDXdVZOa8mW+U2T6m+3/B9v8Md3SD5er9dF/PWb5NwbOxQ4ty4BR1cAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a standard delete modal in its \"deleting\" state",
            "title": "Example of a standard delete modal in its \"deleting\" state",
            "src": "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/fb070/1_2.png",
            "srcSet": ["/static/9f4a6d190a2b4458f6bbc42e8bb9e750/d6747/1_2.png 288w", "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/09548/1_2.png 576w", "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/fb070/1_2.png 1152w", "/static/9f4a6d190a2b4458f6bbc42e8bb9e750/c3e47/1_2.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <p>{`You can choose to show a notification confirming deletion is completed. This is useful when the deletion of the resource takes more than a few moments.`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.36413043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAYAAAAIy204AAAACXBIWXMAAAsSAAALEgHS3X78AAAAwElEQVQoz5WRDYuCUBBF+/9/qiIo+xayNSiVCtbW1dyKNH2+d1LajUUM7cIMw3A5zEeLkpRSv8WjLkfRR5W8/9SqglUZ/yRzmizyC28lMMlSesGcobdA2+n0tzO0vc5gO8c4WziJi1TyuUUtMBY39OuasWfQsYa0Nxpde8TUN1lGDo5wyQpg0wkjEbOIbIyrzfRoMgtXTIIPjLxnZjss8fkeMJUCLw35Fj8cbgGH2MfN4ys54ssTobw0u2HdQ5p47xqaIgH8sXt7AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Example of a successful delete notification",
            "title": "Example of a successful delete notification",
            "src": "/static/9b282ba0b337dd9384021e4221004ad8/fb070/7_updated.png",
            "srcSet": ["/static/9b282ba0b337dd9384021e4221004ad8/d6747/7_updated.png 288w", "/static/9b282ba0b337dd9384021e4221004ad8/09548/7_updated.png 576w", "/static/9b282ba0b337dd9384021e4221004ad8/fb070/7_updated.png 1152w", "/static/9b282ba0b337dd9384021e4221004ad8/c3e47/7_updated.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
      </Column>
    </Row>
    <h2 {...{
      "id": "low-impact"
    }}>{`Low impact`}</h2>
    <p>{`Requiring the user to confirm deletion is generally recommended. However, in very low impact situations, such as when an ‘undo’ option is available, user confirmation may not be required.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      